(function () {
	"use strict";

	angular
		.module('smartermail')
		.service('stropheConnectionService', stropheConnectionService);

	function stropheConnectionService($rootScope, $q,
		$timeout,
		tokenRefreshService,
		claimsService,
		preferencesStorage,
		signalrHubManager, authStorage) {
		const vm = this;
		vm._connection = null;
		vm._attempts = 0;
		const boshConnectionUrl = stSiteRoot + "httpbind";
		vm.connectionStatus = Strophe.Status.DISCONNECTED;
		vm.messageCarbonCallback = null;
		vm.rosterCallback = null;
		vm.logTraffic = false;
		let connectDefer = null;

		function createStropheConnection() {
			return new Strophe.Connection(boshConnectionUrl, null, signalrHubManager.connection);
		}

		vm.parameters = {
			get connection() {
				return vm.connectionStatus === Strophe.Status.CONNECTED ? vm._connection : null;
			},
			get states() {
				return {
					1: { status: "available", label: "FLAGS_AVAILABLE", idx: 0 },
					2: { status: "away", label: "FLAGS_AWAY", idx: 1 },
					3: { status: "dnd", label: "FLAGS_DO_NOT_DISTURB", idx: 2 },
					0: { status: "offline", label: "FLAGS_OFFLINE", idx: 3 }
				};
			},
			get status() {
				let value = preferencesStorage.getSortingFilteringParam("chat", "status");
				switch (vm.connectionStatus) {
					case Strophe.Status.CONNECTING:
						value = "connecting";
						break;
					case Strophe.Status.CONNECTED:
						if (value === undefined) {
							value = "available";
							preferencesStorage.setSortingFilteringParam("chat", "status", value);
						}
						break;
					case Strophe.Status.CONNFAIL:
						value = "failed";
						break;
					case Strophe.Status.DISCONNECTING:
						value = "disconnecting";
						break;
					default:
						value = "offline";
						break;
				}
				return value;
			},
			set status(value) {
				const prev = preferencesStorage.getSortingFilteringParam("chat", "status");
				if (vm.connectionStatus === Strophe.Status.CONNECTED) {
					preferencesStorage.setSortingFilteringParam("chat", "status", value);
					//if (value === "offline") {
					//	vm.disconnect();
					//	return;
					//}
					setStatus(value || prev || "available");
				} else if ((vm.connectionStatus === Strophe.Status.DISCONNECTED ||
						vm.connectionStatus === Strophe.Status.CONNFAIL) &&
					value !== "offline") {
					preferencesStorage.setSortingFilteringParam("chat", "status", value);
					if (prev === "offline") {
						$rootScope.$broadcast('xmpp.reconnect-needed', { status: value });
					}
				}
				$rootScope.$broadcast('xmpp.property-changed', { status: vm.parameters.status });
			},
			get connected() {
				return vm.connectionStatus === Strophe.Status.CONNECTED && vm._connection && vm._connection.authenticated;
			}

		}
		function connectionStatusCallback(status, reason) {
			vm.connectionStatus = status;
			if (vm.logTraffic) console.log("XMPP connection has received a status of " + status, reason);
			switch (status) {
				case Strophe.Status.CONNECTING:
					break;
				case Strophe.Status.DISCONNECTING:
					break;
				case Strophe.Status.AUTHFAIL:
					if (vm._attempt < 3) {
						tokenRefreshService.refreshToken()
							.then(function (newToken) {
								setTimeout(function () { vm.connect(newToken); }, 1000 * vm._attempt);
							});
					} else if (connectDefer) {
						connectDefer.reject("Connecting to XMPP failed. No login credentials found.");
					}
					break;
				case Strophe.Status.CONNFAIL:
				case Strophe.Status.DISCONNECTED:
					if (connectDefer) connectDefer.reject('Connection failed or disconnected');
					clearSession();
					vm._connection = null;
					connectDefer = null;
					break;
				case Strophe.Status.CONNECTED:
					saveSession();
					onConnected();
					if (connectDefer) connectDefer.resolve(getResult());
					break;
			}
			$rootScope.$broadcast('xmpp.property-changed', { status: vm.parameters.status });
		}
		function setStatus(newStatus) {

			switch (newStatus) {
				case "available":
					vm._connection.send(window.$pres());
					break;
				case "away":
					vm._connection.send(window.$pres().c('show').t('away'));
					break;
				case "dnd":
					vm._connection.send(window.$pres().c('show').t('dnd'));
					break;
				case "offline":
					vm._connection.send(window.$pres({type: 'unavailable'}).c('show').t('unavailable'));
					//if (vm.connection)
					//	vm._connection.disconnect();
					//vm.connection = undefined; //vm.contactCategories = [];
					break;
				default:
					return;
			}
			$rootScope.$broadcast('xmpp.property-changed', { status: vm.parameters.status });
		}
		function saveSession() {
			if (vm.connection && vm._connection.jid) {
				const jid = vm._connection.jid;
				const sid = vm._connection._proto.sid;
				const rid = vm._connection._proto.rid;
				sessionStorage.setItem('xmppSession', JSON.stringify({ jid: jid, sid: sid, rid: rid }));
			}
		}

		function getSession() {
			const session = sessionStorage.getItem('xmppSession');
			return session ? JSON.parse(session) : null;
		}

		
		function clearSession() {
			vm._attempts = 0;
			sessionStorage.removeItem('xmppSession');
		}

		function onConnected() {
			vm._connection.rawInput = function (elem) {
				if (vm.logTraffic) console.log("RECV:\r\n", elem);
			}

			vm._connection.rawOutput = function (elem) {
				if (vm.logTraffic) console.log("SENT:\r\n", elem);
			}
		}
		function getResult() {
			return {
				status: preferencesStorage.getSortingFilteringParam("chat", "status") || "available"
			};
		}
		vm.connect = function (token) {
			if (!vm._connection) vm._connection = createStropheConnection();
			//if (vm.parameters.status === "offline") {
			//	if (vm._connection) vm._connection.disconnect();
			//	return $q.resolve(getResult());
			//}
			connectDefer = connectDefer || $q.defer();
			vm._attempt++;

			vm._connection.connect(claimsService.getRootEmail(),
				token,
				connectionStatusCallback);


			return connectDefer.promise;
		};

		vm.disconnect = function () {
			if (vm._connection) {
				vm._connection.disconnect();
			}
		};

	}
})();
