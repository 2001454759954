(function () {
	'use strict';

	angular
		.module('smartermail')
		.factory('chatProviderFactory', chatProviderFactory);

	chatProviderFactory.$inject = ['userDataService', 'simpleXmppService', 'rocketChatService', 'popupService'];

	function chatProviderFactory(userDataService, simpleXmppService, rocketChatService, popupService) {
		let xmppSetStatusTask = undefined;
		const providers =
		{
			0: "SMARTERMAIL",
			1: "ROCKETCHAT"
		}

		const setXmppStatusInterval = (status) => {
			if (xmppSetStatusTask) clearInterval(xmppSetStatusTask);
			xmppSetStatusTask = setInterval(function () {
				if (simpleXmppService.connected) {
					clearInterval(xmppSetStatusTask);
					simpleXmppService.setStatus(status);
				}
			},
				300);
		}
		const service = {
			get chatStatusTypes() {
				return userDataService.user.settings.userMailSettings.chatProviderType === 1
					? rocketChatService.states
					: simpleXmppService.states;

			},
			get connected() {
				if (!userDataService.user.settings.features.enableChat) return false;
				return userDataService.user.settings.userMailSettings.chatProviderType === 1 ? rocketChatService.service.connected : simpleXmppService.parameters.connected;
			},
			get provider() {
				if (!userDataService.user.settings.features.enableChat) return "NONE";
				return providers[userDataService.user.settings.userMailSettings.chatProviderType];
			},
			get status() {
				if (!userDataService.user.settings.features.enableChat) return "none";
				return userDataService.user.settings.userMailSettings.chatProviderType === 1 ? rocketChatService.status : simpleXmppService.status;
			},
			set status(status) {
				switch (service.provider) {
					case "SMARTERMAIL":
						if (simpleXmppService.status === "connecting")
							setXmppStatusInterval(status);
						else
							simpleXmppService.setStatus(status);
						break;
					case "ROCKETCHAT":
						rocketChatService.setStatus(status);
						break;
				}
			}


		};

		service.setStatus = (status) => {
			switch (service.provider) {
				case "SMARTERMAIL":
					simpleXmppService.setStatus(status);
					break;
				case "ROCKETCHAT":
					rocketChatService.setStatus(status);
					break;
			}
		}
		service.getUserStatus = (username) => {
			let status = null;
			switch (service.provider) {
				case "SMARTERMAIL":
					status = simpleXmppService.getUserStatus(username);
					break;
				case "ROCKETCHAT":
					status = rocketChatService.getUserStatus(username);
					break;
			}
			return status;
		}
		service.connect = async (reconnect) => {
			await userDataService.init();
			switch (service.provider) {
				case "SMARTERMAIL":
					if (reconnect) simpleXmppService.close();
					if (rocketChatService.service.connected) rocketChatService.close();
					simpleXmppService.init();
					break;
				case "ROCKETCHAT":
					if (simpleXmppService.connected) simpleXmppService.close();
					if (reconnect) rocketChatService.close();
					await rocketChatService.init();
					break;
				default:
					if (simpleXmppService.connected) simpleXmppService.close();
					if (rocketChatService.service.connected) rocketChatService.close();
					break;
			}
		}
		service.close = async () => {
			if (rocketChatService.service.connected)
				rocketChatService.close();
			if (simpleXmppService.parameters.connected)
				simpleXmppService.close();
		}
		service.generateMenuItems = (event) => {

			switch (service.provider) {
				case "SMARTERMAIL":
					return simpleXmppService.getMenuItems(event);
				case "ROCKETCHAT":
					return rocketChatService.getUnreadChatLinks(event);

			}
			return null;
		}
		service.openMenuLink = (linkUrl) => {
			switch (service.provider) {
				case "SMARTERMAIL":
					simpleXmppService.popout({ jid: linkUrl });
					break;
				case "ROCKETCHAT":
					window.open(linkUrl, "rocket.Chat", "resizable=1, " + popupService.dimensions.rocketChat);
					break;
			}
		}
		service.openLink = (event, email) => {
			switch (service.provider) {
				case "SMARTERMAIL":
					if (simpleXmppService.status === "offline")
						simpleXmppService.setStatus("online");
					if (email) {
						simpleXmppService.popout({ jid: email });
					} else {
						simpleXmppService.popout("recent");
					}
					break;
				case "ROCKETCHAT":

					rocketChatService.openLink(email);
					break;
			}
		}
		return service;
	}
})();