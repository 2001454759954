(function () {
	"use strict";

	angular
		.module("smartermail")
		.service("xmppService", xmppService);

	function xmppService($rootScope,
		$filter,
		$timeout,
		$log,
		$sanitize,
		coreData,
		coreDataContacts,
		coreDataSettings,
		browserNotifications,
		preferencesStorage,
		tokenRefreshService,
		userDataService,
		claimsService,
		authStorage,
		signalrHubManager,
		stropheConnectionService) {

		// TODO: Get pics from api/v1/contacts/domain
		// Data
		var vm = this;

		vm.boshUrl = null;
		vm.host = null;
		vm.username = null;
		vm.email = null;
		//vm.status = "connecting";
		vm.contactCategories = [];
		vm.logTraffic = false;
		var idCounter = 1;
		vm.firstConnection = false;

		$rootScope.$on('signalRHubManagerReconnected', reconnect);
		$rootScope.$on('xmpp.reconnect-needed', reconnect);
		function reconnect() {
			if (!stropheConnectionService.connected) {
				vm.init();
			}
		}
		vm.parameters = {
			get connected() {
				return stropheConnectionService.parameters.connected;
			},
			get status() {
				return stropheConnectionService.parameters.status;
			},

			get prevStatus() {
				var value = preferencesStorage.getSortingFilteringParam("chat", "prevStatus");
				if (value === undefined) {
					value = "available"; preferencesStorage.setSortingFilteringParam("chat", "prevStatus", value);
				}
				return value;
			},
			set prevStatus(value) { preferencesStorage.setSortingFilteringParam("chat", "prevStatus", value); },

			get unreadCounts() {
				var value = preferencesStorage.getSortingFilteringParam("chat", "unreadCounts");
				if (value === undefined) {
					value = {}; preferencesStorage.setSortingFilteringParam("chat", "unreadCounts", value);
				}
				return value;
			},
			set unreadCounts(value) { preferencesStorage.setSortingFilteringParam("chat", "unreadCounts", value); },

			get isPopup() {
				return window.opener && !window.opener.closed;
			},


			get notify() {
				var value = preferencesStorage.getSortingFilteringParam("chat", "notify");
				if (value === undefined)
					value = coreDataSettings.userSettings.notifyOnChatMessages;
				return value;
			},

		};

		// Functions
		vm.close = close;
		vm.markRead = markRead;
		vm.sendMessage = sendMessage;
		vm.notifyComposing = notifyComposing;
		vm.setStatus = setStatus;
		vm.stopComposing = stopComposing;
		vm.findContact = findContact;
		vm.queryHistory = queryHistory;
		vm.init = init;
		// Startup
		if (!window.Strophe) {
			throw new Error('Please make sure to include Strophe library. http://strophe.im/strophejs/');
		}

		activate();

		function activate() {
			$(window).on("beforeunload", function () {
				close();

			});
			
		}
		function init() {
			stropheConnectionService.connect(authStorage.getToken())
				.then(function (success) {
					onConnected();
					cleanUnreadCounts();

				});
		}
		function cleanUnreadCounts() {
			var counts = vm.parameters.unreadCounts;
			vm.parameters.unreadCounts =
				Object.keys(counts).reduce((acc, key) => {
					if (counts[key].unread > 0) {
						acc[key] = counts[key];
					}
					return acc;
				}, {});
		}

		function checkSelectContact() {
			var jid = preferencesStorage.getSortingFilteringParam("chat", "selectContact");
			if (jid) {
				$rootScope.$broadcast("xmpp.select-user", { contact: findContact(jid) });
				preferencesStorage.setSortingFilteringParam("chat", "selectContact", "");
				return;
			}

			var selectRecent = preferencesStorage.getSortingFilteringParam("chat", "selectRecentUnread");
			if (!selectRecent) return;
			preferencesStorage.setSortingFilteringParam("chat", "selectRecentUnread", undefined);

			var counts = vm.parameters.unreadCounts;
			var mostRecent = { unread: 0, time: moment(0) };
			for (var key in counts) {
				var contact = counts[key];
				if (contact.unread <= 0) continue;

				contact.time = moment(contact.time);
				if (contact.time > mostRecent.time) {
					mostRecent = contact;
					mostRecent.jid = key;
				}
			}

			if (mostRecent.jid)
				$rootScope.$broadcast("xmpp.select-user", { contact: findContact(mostRecent.jid) });
		}

		// Implementation

		function close() {
			if (stropheConnectionService.parameters.connected) {
				stropheConnectionService.disconnect();
			}
		}

		function markRead(contact) {
			const signalChange = contact && contact.unreadCount;
			contact.unreadCount = 0;
			storeUnreadCount(contact);
			recalculateUnread();
			if (signalChange) signalrHubManager.connection.invoke("chatMarkUserAsRead", authStorage.getRefreshToken(), contact.jid);
		}

		var isTyping = false;
		function sendMessage(toJID, message) {
			if (!message || !stropheConnectionService.parameters.connected) { return; }

			if (isTyping) {
				stropheConnectionService.parameters.connection.Messaging.active(toJID);
				isTyping = false;
			}

			stropheConnectionService.parameters.connection.send($msg({ to: toJID, type: 'chat' }).c('body').t(message).tree());
			var foundItem = findContact(toJID);
			if (!foundItem.conversation)
				foundItem.conversation = [];

			message = $('<div/>').text(message).html();
			var conversationItem = { text: linkify(message, true), jid: toJID, name: vm.displayName, pic: null, isMe: true, dt: new Date() };
			foundItem.conversation.push(conversationItem);

			$rootScope.$broadcast('xmpp.conversation-changed', { jid: toJID, conversation: foundItem.conversation });
		}

		function notifyComposing(toJID) {
			if (!stropheConnectionService.parameters.connected)
				return;
			if (isTyping)
				return;

			isTyping = true;
			stropheConnectionService.parameters.connection.Messaging.composing(toJID);
		}

		function stopComposing(toJID) {
			if (!stropheConnectionService.parameters.connected)
				return;
			if (!isTyping)
				return;
			isTyping = false;
			stropheConnectionService.parameters.connection.Messaging.paused(toJID);
		}

		function setStatus(newStatus) {
			stropheConnectionService.parameters.status = newStatus;

		}

		function onConnected() {
			var xmppRequest = $iq({ type: "get", id: "_roster_" + (idCounter++) }).c("query", { xmlns: Strophe.NS.ROSTER });
			stropheConnectionService.parameters.connection.sendIQ(xmppRequest, onRoster);
			stropheConnectionService.parameters.connection.messageCarbons.enable(onMessageCarbon);
			setStatus(vm.parameters.status);
		}


		function onMessageCarbon(carbon) {
			var body = $(carbon.innerMessage).children("body").text() || "";
			if (!body)
				return;

			if (carbon.direction === 'sent') {
				var bareTo = Strophe.getBareJidFromJid(carbon.to);
				var foundItem = findContact(bareTo);
				if (foundItem && carbon.type == "chat") {
					foundItem.conversation = foundItem.conversation || [];
					body = $('<div/>').text(body).html();
					var conversationItem = { text: linkify(body, true), jid: bareTo, name: vm.displayName, pic: null, isMe: true, dt: new Date() };
					foundItem.conversation.push(conversationItem);
					$rootScope.$broadcast('xmpp.conversation-changed', { jid: bareTo, conversation: foundItem.conversation });
				}
			}
			else {
				onMessage(carbon.innerMessage);
			}
		}

		async function onRoster(roster) {
			//I'm using a try catch here because i've seen errors happen here and chrome doesn't catch and throw them itself.
			try {
				if (!vm.contactCategories)
					vm.contactCategories = [];

				for (let item of $(roster).find("item")) {
					let contact = await contactFromIqItem(item);
					addRosterContact(contact);
					addContact(contact.jid, contact.name);
				}

				$rootScope.$broadcast('xmpp.contacts-changed', { contactCategories: vm.contactCategories });
				loadUnreadCounts();
				recalculateUnread();
				checkSelectContact();

				stropheConnectionService.parameters.connection.addHandler(onRosterChanged, Strophe.NS.ROSTER, 'iq', 'set');
				stropheConnectionService.parameters.connection.addHandler(onPresence, null, 'presence');
				stropheConnectionService.parameters.connection.addHandler(onIQ, null, 'iq');
				stropheConnectionService.parameters.connection.addHandler(onMessage, null, 'message', "chat");
				stropheConnectionService.parameters.connection.addHandler(onGroupMessage, null, 'message', "groupchat");
				vm.setStatus(vm.parameters.status);
			} catch (exception) {
				$log.error(exception); //Chrome doesn't seem to like the error I get here so the throw statement doesn't work on its on.
				throw new Error(exception);
			} finally {
				vm.firstConnection = false;
			}
			//checkRegisteredStatuses();
		}

		async function onRosterChanged(roster, iq) {
			for (let item of $(roster).find("item")) {
				var contact = await contactFromIqItem(item);
				if (contact.subscription == "remove")
					removeRosterContact(contact);
				else
					addRosterContact(contact);
			}
			$rootScope.$broadcast('xmpp.contacts-changed', { contactCategories: vm.contactCategories });
			recalculateUnread();
			//checkRegisteredStatuses();
			return true;
		}

		vm.joinRoom = joinRoom;
		function joinRoom(roomJid, userAlias, onRoomJoined) {
			vm.onRoomJoined = onRoomJoined;
			vm.roomJoining = roomJid + "/" + userAlias;
			stropheConnectionService.parameters.connection.send($pres({ to: roomJid + "/" + userAlias }).c('x', { xmlns: "http://jabber.org/protocol/muc" }));
		}

		var unavailableTimeouts = {};
		function onPresence(presence) {
			const presences = presence.length !== undefined ? Array.from(presence) : [presence];

			const { online, offline } = presences.reduce((acc, curr) => {
				if ($(curr).attr('type') === 'unavailable') {
					acc.offline.push(curr);
				} else {
					acc.online.push(curr);
				}
				return acc;
			}, { online: [], offline: [] });
			offline.forEach(setPresence);
			online.forEach(setPresence);

			return true;
		}

		function setPresence(presence) {
			var ptype = $(presence).attr('type');
			var from = $(presence).attr('from');
			var bareJid = Strophe.getBareJidFromJid(from);
			var resourceID = Strophe.getResourceFromJid(from);

			if (bareJid.toLowerCase() == userDataService.user.emailAddress.toLowerCase()) return;

			if (vm.roomJoining == from && vm.onRoomJoined) {
				if (ptype === 'error')
					vm.onRoomJoined(false, from);
				var isRoomJoinRequestResponse = $(presence).find("status[code='110']").length > 0;
				if (isRoomJoinRequestResponse)
					vm.onRoomJoined(true, from);
			}

			if (ptype === 'error')
				return;

			var foundItem = findContact(bareJid);
			if (foundItem) {
				if (unavailableTimeouts[bareJid]) {
					$timeout.cancel(unavailableTimeouts[bareJid]);
					delete unavailableTimeouts[bareJid];
				}

				if (!foundItem.statuses) foundItem.statuses = {};

				if (foundItem.status === "room")
					foundItem.status = "room";
				else if (ptype === 'unavailable') {
					if (resourceID)
						foundItem.statuses[resourceID] = 'offline';

					var offline = true;
					for (var key in foundItem.statuses) {
						if (foundItem.statuses[key] != 'offline') {
							offline = false; break;
						}
					}

					if (offline) {
						foundItem.status = 'offline';
					} else {
						var available = false;
						var dnd = false;
						for (var key in foundItem.statuses) {
							if (foundItem.statuses[key] === 'offline')
								continue;
							else if (foundItem.statuses[key] === 'available') {
								available = true; break;
							}
							else if (foundItem.statuses[key] === 'dnd')
								dnd = true;
						}

						if (!available) {
							if (dnd)
								foundItem.status = "dnd";
							else
								foundItem.status = "away";
						}
					}
				}
				else {
					var show = $(presence).find("show").text();

					if (resourceID)
						foundItem.statuses[resourceID] = show === "" || show === "chat" ? "available" : (show === "dnd" ? "dnd" : "away");

					if (show === "" || show === "chat")
						foundItem.status = "available";
					else if (show === "dnd")
						foundItem.status = "dnd";
					else {
						// foundItem.status = "away";
						var away = true;
						for (var key in foundItem.statuses) {
							if (foundItem.statuses[key] === 'offline')
								continue;
							if (foundItem.statuses[key] != 'away') {
								away = false; break;
							}
						}

						if (away) {
							foundItem.status = 'away';
						}
					}
				}
				$rootScope.$broadcast('xmpp.contacts-changed', { contactCategories: vm.contactCategories, loaded: true });
			}

			return;
		}

		function onIQ(iq) {
			/*
			<body xmlns='http://jabber.org/protocol/httpbind'><iq xmlns='jabber:client' type='set' from='xmpptest@familykid.com' to='xmpptest@familykid.com/Client_2f4a57'><query xmlns='jabber:iq:roster'><item jid='xmpptestgroup@familykid.com' subscription='both' name='XMPPTestGroup'><group>familykid.com - Aliases</group></item></query></iq></body>
			<body xmlns='http://jabber.org/protocol/httpbind'><presence xmlns='jabber:client' from='xmpptestgroup@familykid.com' to='xmpptest@familykid.com/Client_2f4a57'/><presence xmlns='jabber:client' from='xmpptest@familykid.com/Client_2f4a57' to='xmpptestgroup@familykid.com'/><presence xmlns='jabber:client' from='xmpptestgroup@familykid.com'/></body>
			*/
			return true;
		}

		var contact;
		var queryId;
		function queryHistory(contactToQuery) {
			contact = contactToQuery;
			var id = queryId = new Date().getTime();
			contactToQuery.archived_conversation = contactToQuery.archived_conversation || [];
			stropheConnectionService.parameters.connection.mam.query(vm.username, {
				'with': contactToQuery.jid,
				'max': 75,
				'queryid': id,
				onMessage: function (message) {
					try {
						var retqueryid = $(message).find('result').attr('queryid');
						if (parseInt(retqueryid) !== queryId)
							return true;
						else if (id != queryId)
							return;

						var delayindicator = $(message).find("forwarded delay");
						var dt = new Date(delayindicator ? delayindicator.attr("stamp") : null);
						var msg = $(message).find("forwarded message");
						var from = Strophe.getBareJidFromJid(msg.attr("from"));
						var body = msg.find("body").text();
						var foundContact = [];
						for (var i = 0; i < vm.contactCategories.length; ++i) {
							if (foundContact.length > 0) { break; }
							foundContact = $.grep(vm.contactCategories[i].contacts, function (c) { return c.jid === from });
						}
						var name = '';
						if (foundContact.length > 0) {
							name = contact.name;
						}
						if (!name) {
							if (from === coreData.user.emailAddress) {
								name = coreData.user.displayName;
							}
						}

						body = $('<div/>').text(body).html();
						var m = { text: linkify(body, true), jid: from, name: (name ? name : from), pic: null, isMe: from == vm.username, dt: dt };
						contact.archived_conversation.push(m);
						$rootScope.$broadcast('xmpp.conversation-changed', { jid: Strophe.getBareJidFromJid(contact.jid), archived_conversation: contact.archived_conversation });
					} catch (err) {
						$log.error(err);
					}
					return true;
				},
				onComplete: function (response) {
					$rootScope.$broadcast('xmpp.query-history-complete');
					return true;
				}
			});
		}

		function onMessage(msg) {
			try {
				var from = $(msg).attr("from");
				var body = $(msg).children("body").text() || "";
				var state = $(msg).find("inactive,active,composing,paused,gone");
				var bareJid = Strophe.getBareJidFromJid(from);
				var msgType = $(msg).attr('type');
				var foundItem = findContact(bareJid);
				if (foundItem) {
					var name = foundItem.name || bareJid;
					if (bareJid === coreData.user.emailAddress) {
						name = coreData.user.displayName ? coreData.user.displayName : name;
					}

					if (msgType == 'chat' && body) {
						var html_body = $('html > body', msg);
						if (html_body.length > 0) {
							html_body = $sanitize($('<div>').append(html_body.contents()).html());
						} else {
							html_body = null;
						}

						var isplain = !html_body;
						if (!html_body) {
							html_body = $('<div/>').text(body).html();
						}

						if (!foundItem.convoAtBottom || !document.hasFocus())
							foundItem.unreadCount++;
						if (!foundItem.conversation)
							foundItem.conversation = [];
						var message = { text: linkify(html_body, isplain), jid: bareJid, name: name, pic: null, isMe: bareJid == vm.username, dt: new Date() };
						foundItem.conversation.push(message);

						$rootScope.$broadcast('xmpp.conversation-changed', { jid: bareJid, conversation: foundItem.conversation });
						storeUnreadCount(foundItem);
						recalculateUnread();

						// TODO: Check if this is already a visible page or not
						if (coreDataSettings.userSettings.notifyOnChatMessages && (!foundItem.convoAtBottom || !document.hasFocus())) {
							var convertedHtml = $("<div>" + html_body + "</div>").text();
							if (convertedHtml.toLowerCase().indexOf('http://') == 0 ||
								convertedHtml.toLowerCase().indexOf('https://') == 0)
								convertedHtml = $filter('translate')('LINK_RECEIVED');

							browserNotifications.show(name,
								{
									body: convertedHtml,
									tag: bareJid,
									icon: (stSiteRoot || '/') + 'interface/img/notifications/chat.png',
									notifyClick: function (e) {
										window.focus();
										$rootScope.$broadcast("xmpp.select-user", { contact: foundItem });
									}
								});
						}

					}

					if (state.length > 0) {
						var composing = $(msg).find("composing");
						foundItem.isTyping = composing.length > 0;
						$rootScope.$broadcast('xmpp.typing-changed', { jid: bareJid, contact: foundItem });
					}
				}
			} catch (ex) { $log.error(ex); }
			return true;
		}

		function onGroupMessage(iq) {
			return true;
		}

		// Private Functions
		function decodeMessage(msg) {
			var from = msg.getAttribute('from');
			var type = msg.getAttribute('type');
			var elems = msg.getElementsByTagName('body');

			if (type === 'chat' && elems.length > 0) {
				var body = elems[0];
				this.onMessage({
					from: from,
					text: Strophe.getText(body)
				});
			}
			return true;
		}

		function linkify(text, isplain) {
			var added = {};
			var textToAppend = "";
			var textToPrepend = "";
			var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
			var aRegex = /(<a[^>]*href=)(('([^']*)'|"([^"]*)"|([\S]*))[^>]*>(.*?)<\/a>)/g;

			var replaced = text;
			if (!isplain) {
				replaced = replaced.replace(aRegex, '<a target="blank_" href=$2');
			}

			replaced = replaced.replace(urlRegex, function (url) {

				if (added[url] === undefined) {
					if (/\.(gif|png|jpe?g)$/i.test(url)) {
						var parts = url.split('/d/');
						var newUrl = parts[0] + '/api/v1/filestorage/download-file-preview/' + parts[1];
						newUrl = newUrl.replace("https:", "http:");
						textToAppend += "<a href='" + url + "' target='_blank' class='chat-image'><img class='st-loading-image' src='" + newUrl + "'></a>";
					}

					var youTube1 = /youtube\.com\/(?:watch\?v=|embed\/)([a-zA-Z0-9-_]+)/gi;
					var match = youTube1.exec(url);
					if (match) {
						textToAppend += "<a href='" + url + "' target='_blank' rel='noopener noreferrer' class='chat-image'><i class='toolsicon toolsicon-youtube sidelogo'></i><img src='http://img.youtube.com/vi/" +
							match[1]
							+ "/0.jpg'></a>";
					}

					var youTube2 = /youtu\.be\/([a-zA-Z0-9-_]+)/gi;
					var match = youTube2.exec(url);
					if (match) {
						textToAppend += "<a href='" + url + "' target='_blank' rel='noopener noreferrer' class='chat-image'><i class='toolsicon toolsicon-youtube sidelogo'></i><img src='http://img.youtube.com/vi/" +
							match[1]
							+ "/0.jpg'></a>";
					}

					var appearIn = /https:\/\/appear.in\/.+/gi;
					var match = appearIn.exec(url);
					if (match) {
						textToPrepend += $filter("translate")("CHAT_SECTION_VIDEO_CHAT_LINK") + "<br/>";
					}

					var appearIn = /https:\/\/meet.jit.si\/.+/gi;
					var match = appearIn.exec(url);
					if (match) {
						textToPrepend += $filter("translate")("CHAT_SECTION_VIDEO_CHAT_LINK") + "<br/>";
					}

					var etherpad = /https:\/\/etherpad.wikimedia.org\/p\/.+/gi;
					var match = etherpad.exec(url);
					if (match) {
						textToPrepend += $filter("translate")("CHAT_SECTION_DOCUMENT_SHARING_LINK") + "<br/>";
					}

					added[url] = true;
				}

				if (isplain)
					return '<a href="' + url + '" rel="noopener noreferrer" target="_blank">' + url + '</a>';
				else
					return url;
			});

			if (!isplain) {
				// TODO: Add target blank to any URLs found in non-plain parts
			}

			return textToPrepend + replaced + textToAppend;
		}

		function recalculateUnread() {
			var temp = 0;
			for (var cat in vm.contactCategories) {
				for (var contactIndex in vm.contactCategories[cat].contacts) {
					var contact = vm.contactCategories[cat].contacts[contactIndex];
					if (contact)
						temp += contact.unreadCount;
				}
			}
			if (vm.unread != temp) {
				vm.unread = temp;
				$rootScope.$broadcast('xmpp.property-changed', { unreadCount: temp });
			}
		}

		function storeUnreadCount(contact) {
			var counts = vm.parameters.unreadCounts;

			if (!counts[contact.jid]) counts[contact.jid] = {};
			counts[contact.jid].unread = contact.unreadCount;
			counts[contact.jid].time = moment();

			vm.parameters.unreadCounts = counts;

		}

		function loadUnreadCounts() {
			var counts = vm.parameters.unreadCounts;

			if (!vm.contactCategories || !vm.contactCategories[0]) {
				vm.contactCategories = [{ contacts: [] }];
			}

			for (var key in counts) {
				var foundItem = findContact(key);
				if (foundItem) foundItem.unreadCount = counts[key].unread;
				//else vm.contactCategories[0].contacts.push({ jid: key, unreadCount: counts[key].unread });
			}
		}

		function addContact(jid, displayName) {
			var iq = $iq({ type: "set" })
				.c("query", { xmlns: Strophe.NS.ROSTER })
				.c("item", { jid: jid, name: displayName });
			stropheConnectionService.parameters.connection.sendIQ(iq);
			stropheConnectionService.parameters.connection.send($pres({ to: jid, "type": "subscribe" }));
		}

		async function contactFromIqItem(iqItem) {
			var jid = $(iqItem).attr("jid");
			var bareJid = Strophe.getBareJidFromJid(jid);
			var displayJid = jid.split("@")[0] + "@" + coreData.user.domain;
			var userName = jid.split("@")[0];
			var name = $(iqItem).attr("name") || bareJid;
			var subscription = $(iqItem).attr("subscription");
			var group = $(iqItem).find("group").text() || "";

			await coreDataContacts.ensureSourcesLoadedPromise();
			await coreDataContacts.ensureContactsLoadedPromise();

			var contact = coreDataContacts.getContactByEmail(jid);
			var pic = null;
			if (contact && contact.image && !contact.image.indexOf('data=default') > -1) { pic = contact.image }
			return {
				jid: jid,
				bareJid: bareJid,
				displayJid: displayJid,
				userName: userName,
				name: name,
				subscription: subscription,
				username: contact.userName,
				group: group,
				pic: pic
			};
		}

		function findContact(jid) {
			for (var i = 0; i < vm.contactCategories.length; i++) {
				var theList = vm.contactCategories[i];
				for (var j = 0; j < theList.contacts.length; j++) {
					var foundItem = theList.contacts[j];
					if (foundItem && foundItem.jid == jid)
						return foundItem;
				}
			}
			return null;
		}

		function addRosterContact(contact) {
			if (!contact || !vm.contactCategories) return;
			var foundList;
			for (var i = 0; i < vm.contactCategories.length; i++) {
				var thisList = vm.contactCategories[i];
				if (thisList.name == contact.group) {
					foundList = thisList;
					break;
				}
			}
			if (!foundList) {
				foundList = { name: contact.group, open: true, contacts: [] };
				vm.contactCategories.push(foundList);
			}

			// If we came back online we want to keep stuff like composeContents while updating the rest of the contact
			for (var i = 0; i < foundList.contacts.length; i++) {
				if (foundList.contacts[i].jid == contact.bareJid) {
					//$.extend(foundList.contacts[i], { name: contact.name, jid: contact.bareJid, displayJid: contact.displayJid, status: contact.group.endsWith("Aliases") ? "room" : "offline", unreadCount: 0, pic: contact.pic })
					var composeContents = foundList.contacts[i].composeContents;
					foundList.contacts[i] = { name: contact.name, jid: contact.bareJid, displayJid: contact.displayJid, userName: contact.userName, status: foundList.contacts[i].status, unreadCount: 0, pic: contact.pic };
					foundList.contacts[i].composeContents = composeContents;
					return;
				}
			}
			// Status room if alias
			foundList.contacts.push({ name: contact.name, jid: contact.bareJid, displayJid: contact.displayJid, userName: contact.userName, status: contact.group.endsWith("Aliases") ? "room" : "offline", unreadCount: 0, pic: contact.pic });
		}

		function removeRosterContact(contact) {
			if (!contact || !vm.contactCategories) return;
			for (var catIndex = 0; catIndex < vm.contactCategories.length; catIndex++) {
				var cat = vm.contactCategories[catIndex];
				for (var contactIndex = 0; contactIndex < cat.contacts.length; contactIndex++) {
					var c = cat.contacts[contactIndex];
					if (c.jid == contact.bareJid) {
						cat.contacts.splice(contactIndex, 1);
						contactIndex--;
					}
				}
			}
		}
	}

})();